@import './plyr.css';

@font-face {
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 100 900;
    font-stretch: 62% 125%;
    font-display: block;
    src: url('../assets/font/archivo.woff2') format('woff2');
}

@font-face {
    font-family: 'Thin 100';
    font-style: normal;
    font-weight: 100;
    font-stretch: 112.5%;
    font-display: block;
    src: url('../assets/font/archivo-100.woff') format('woff');
}

@font-face {
    font-family: 'Extra-bold 800';
    font-style: normal;
    font-weight: 800;
    font-stretch: 125%;
    font-display: block;
    src: url('../assets/font/archivo-800.woff') format('woff');
}

:root {
    --primary-ui-color: hsl(0, 100%, 100%);
    --w04: 'Archivo', 'Thin 100', sans-serif;
    --w05: 'Archivo', 'Extra-bold 800', sans-serif;
    --plyr-color-main: var(--primary-ui-color);
    --plyr-control-radius: 50%;
    --plyr-video-control-background-hover: none;
    --plyr-progress-loading-size: 0px;
    --plyr-range-thumb-height: 0px;
    --plyr-range-thumb-shadow: none;
    --plyr-range-track-height: 2px;
    --plyr-font-family: var(--w04);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: hsla(0, 0%, 0%, 0%);
}

::selection {background: hsla(0, 100%, 100%, 50%);}
::-moz-selection {background: hsla(0, 100%, 100%, 50%);}

*, *::before, *::after {box-sizing: border-box;}

html {
    font-size: 62.5%;
    color: var(--primary-ui-color);
    background: hsl(0, 0%, 0%);
}

body {
    min-height: 100vh;
    margin: 0;
}

a {
    text-decoration: none;
    color: inherit;
}

br {user-select: none;}

.no_scroll {overflow: hidden;}

.loader {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 2;
    display: none;
    background: hsl(0, 0%, 20%);
    transform: translate(-50%, -50%);
    pointer-events: none;
}

.loader__progress_bar_container {
    position: relative;
    width: 100px;
    height: 2px;
}

.loader__progress_bar {
    position: absolute;
    width: 100%;
    height: 100%;
    background: var(--primary-ui-color);
    transform: scaleX(0);
    transform-origin: left;
}

.to_home {
    position: fixed;
    top: 25px;
    left: 50%;
    z-index: 2;
    display: none;
    width: calc(48px + 50px);
    height: calc(24px + 50px);
    padding: 25px 0 0 25px;
    transform: translateX(-50%);
}

.to_home__logo,
.menu_page__logo {
    width: 48px;
    height: 24px;
    fill: var(--primary-ui-color);
}

.menu,
.close_player {
    position: fixed;
    left: 50%;
    bottom: 25px;
    z-index: 2;
    display: none;
    width: calc(50px + 50px);
    height: calc(36px + 50px);
    padding: 25px 0 0 25px;
    transform: translate(-50%, 0);
    pointer-events: none;
}

.close_player {z-index: 4;}

.menu--open__ui_fragment {
    display: block;
    width: 50px;
    height: 2px;
    margin: 11px 0;
    background: var(--primary-ui-color);
}

.menu--close,
.close_player {
    padding: 0 0 0 25px;
    cursor: pointer;
}

.menu--close__in,
.close_player__in {
    position: relative;
    top: 47.5%;
    display: block;
    width: 50px;
    height: 2px;
}

.menu--close__in:nth-child(1), .close_player__in:nth-child(1) {transform: translateY(50%) rotate(45deg);}
.menu--close__in:nth-child(2), .close_player__in:nth-child(2) {transform: translateY(-50%) rotate(-45deg);}

.menu--close__ui_fragment,
.close_player__ui_fragment {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background: var(--primary-ui-color);
}

.menu--close__in:nth-child(1) .menu--close__ui_fragment, .close_player__in:nth-child(1) .close_player__ui_fragment {transform-origin: left;}
.menu--close__in:nth-child(2) .menu--close__ui_fragment, .close_player__in:nth-child(2) .close_player__ui_fragment {transform-origin: right;}

.gradient {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 15vh;
    pointer-events: none;
}

.gradient--ceil {
    top: 0;
    background: linear-gradient(180deg, hsla(0, 0%, 0%, 100%) 0%, hsla(0, 0%, 0%, 0%) 100%);
}

.gradient--floor {
    bottom: 0;
    background: linear-gradient(180deg, hsla(0, 0%, 0%, 0%) 0%, hsla(0, 0%, 0%, 100%) 100%);
}

.scroll_indicator,
.scroll_indicator_index {
    position: fixed;
    bottom: 30vh;
    right: 10px;
    z-index: 2;
    width: 50px;
    height: 50px;
    visibility: hidden;
}

.scroll_indicator {
    border: 2px solid hsla(0, 100%, 100%, 20%);
    border-top: 2px solid var(--primary-ui-color);
    border-radius: 50%;
}

.scroll_indicator_index {
    font: 100 1.6rem var(--w04);
    font-stretch: 112.5%;
    text-align: center;
    line-height: 50px;
    user-select: none;
}

.scroll_hint {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: -300;
    visibility: hidden;
    font: 800 9vw var(--w05);
    font-stretch: 125%;
    text-transform: uppercase;
    -webkit-text-stroke: 1px hsl(0, 0%, 40%);
    color: hsla(0, 0%, 0%, 0%);
    transform: translate(-50%, -50%);
    user-select: none;
}

.scroll_layers {visibility: hidden;}

.scroll_layers,
.scroll_layers__page_content_layer,
.scroll_layers__page_title_layer {
    position: absolute;
    width: 100%;
    height: 100vh;
}

.scroll_layers__page_content_layer,
.scroll_layers__page_title_layer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.scroll_layers__page_content_layer {perspective: 1px;}

.scroll_layers__page_content {
    position: absolute;
    width: 100%;
    height: auto;
}

.scroll_layers__overlay {
    position: fixed;
    width: 100%;
    height: 100vh;
    background: hsla(0, 0%, 0%, 20%);
}

.scroll_layers__page_title,
.scroll_layers__page_title a {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}

.scroll_layers__page_title {
    width: 100%;
    height: 100%;
    margin: 0;
    font: 800 12vw var(--w05);
    font-stretch: 125%;
}

.scroll_layers__page_title a {
    width: inherit;
    height: 30vw;
    white-space: nowrap;
    pointer-events: none;
}

.scroll_index {
    position: fixed;
    left: 50%;
    bottom: 60px;
    z-index: 2;
    display: none;
    visibility: hidden;
    font: 100 1.6rem var(--w04);
    font-stretch: 112.5%;
    letter-spacing: 10px;
    transform: translateX(-50%);
    user-select: none;
}

.scroll_index > .scroll_index__number + span {letter-spacing: 0;}

.__isMobile, .__isWindows {display: none;}

.mobile,
.os {
    display: none;
    max-height: 100vh;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
}

.mobile__child,
.os__child {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    scroll-snap-align: center;
}

.mobile__thumb,
.os__in {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 800px;
    height: 450px;
}

.os__in {position: relative;}

.mobile__thumb_img_1 {background: no-repeat center/contain url('../assets/images/mobile/the-country.jpg');}
.mobile__thumb_img_2 {background: no-repeat center/contain url('../assets/images/mobile/reel-2021.jpg');}
.mobile__thumb_img_3 {background: no-repeat center/contain url('../assets/images/mobile/mavic.jpg');}
.mobile__thumb_img_4 {background: no-repeat center/contain url('../assets/images/mobile/sora.jpg');}
.mobile__thumb_img_5 {background: no-repeat center/contain url('../assets/images/mobile/ocean.jpg');}
.mobile__thumb_img_6 {background: no-repeat center/contain url('../assets/images/mobile/nature.jpg');}
.mobile__thumb_img_7 {background: no-repeat center/contain url('../assets/images/mobile/g-airspace.jpg');}

.os__page_content, .os__page_title {position: absolute;}

.os__page_content,
.os__page_title {
    width: inherit;
    height: inherit;
}

.os__page_title,
.mobile__page_title {
    font: 800 12vw var(--w05);
    font-stretch: 125%;
    text-align: center;
    white-space: nowrap;
}

.os__page_title {
    margin: 0;
    line-height: 450px;
}

.mobile__page_title {width: 80vw;}

.mobile__page_title a,
.os__page_title a {
    display: block;
    width: inherit;
}

.os__page_title a {height: inherit;}

.os__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: hsla(0, 0%, 0%, 20%);
}

.isMobile, .isWindows {display: block;}

.menu_page_container {
    display: flex;
    align-items: center;
    height: 100vh;
}

.menu_page {
    display: flex;
    flex-direction: column;
    width: 60%;
    margin: 0 auto;
}

.menu_page__list {
    margin: 0;
    padding: 0;
    overflow: hidden;
    font: 800 8vw var(--w05);
    font-stretch: 125%;
    list-style-type: none;
}

.menu_page__list_item {
    position: relative;
    text-align: right;
    text-transform: uppercase;
    line-height: 100%;
    user-select: none;
    pointer-events: none;
}

.menu_page__list_item a span {
    -webkit-text-stroke: 1px hsl(0, 0%, 40%);
    color: hsla(0, 0%, 0%, 0%);
}

.menu_page__to_self {cursor: pointer;}

.menu_page__list_item:nth-child(1) a span::after {content: 'home';}
.menu_page__list_item:nth-child(2) a span::after {content: 'works';}
.menu_page__list_item:nth-child(4) a span::after {content: 'youtube';}
.menu_page__list_item:nth-child(5) a span::after {content: 'vimeo';}
.menu_page__list_item:nth-child(6) a span::after {content: 'skypixel';}

.menu_page__list_item a span::after {
    position: absolute;
    top: 0;
    right: 0;
    -webkit-text-stroke: 1px var(--primary-ui-color);
    color: var(--primary-ui-color);
    clip-path: inset(0 0 0 0);
    transition: clip-path .3s ease;
}

.menu_page__list_item a:hover span::after {clip-path: inset(0 0 0 100%);}

.menu_page__footer {
    align-self: flex-start;
    margin: 8vh 0 0 0;
}

.menu_page__to_home {
    display: inline-block;
    width: calc(48px + 50px);
    height: calc(24px + 50px);
    padding: 25px 0 0 25px;
    transform: translateX(-25px);
}

.menu_page__about,
.menu_page__copyright {
    font: 100 1.6rem var(--w04);
    font-stretch: 112.5%;
}

.menu_page__about {
    width: 190px;
    margin: 5px 0 20px 0;
}

#c {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    pointer-events: none;
}

.gl_container {margin: 500px 0 100px 0;}

.gl {
    display: grid;
    grid-template-columns: 180px;
    justify-content: center;
    gap: 100px 0;
}

.gl__to_this {display: block;}

.gl__plane {
    width: 180px;
    height: 101.25px;
}

.gl__plane img {display: none;}

.gl__page_title {
    display: inline-block;
    margin: 20px 0 0 10px;
    font: 100 2.4rem var(--w04);
    font-stretch: 112.5%;
}

.gl__page_title::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: var(--primary-ui-color);
    transform: scaleX(0);
    transition: transform .3s;
}

.gl__to_this:hover .gl__page_title::after {transform: scaleX(1);}

.mobile_gl {
    width: inherit;
    height: inherit;
}

.to_self_back {
    position: relative;
    left: 50%;
    display: inline-block;
    margin: 0 0 150px 0;
    font: 800 8vw var(--w04);
    font-stretch: 125%;
    text-transform: uppercase;
    -webkit-text-stroke: 1px hsl(0, 0%, 40%);
    color: hsla(0, 0%, 0%, 0%);
    transform: translateX(-50%);
    cursor: pointer;
    user-select: none;
}

.to_self_back::after {
    content: 'top';
    position: absolute;
    top: 0;
    left: 0;
    -webkit-text-stroke: 1px var(--primary-ui-color);
    color: var(--primary-ui-color);
    clip-path: inset(0 100% 0 0);
    transition: clip-path .3s ease;
}

.to_self_back:hover::after {clip-path: inset(0 0 0 0);}

.ui_page_transition_component {
    position: fixed;
    width: 100%;
    height: 50vh;
    background: hsl(0, 0%, 0%);
    transform: scaleY(1);
}

.ui_page_transition_component--ceil {
    top: 0;
    transform-origin: top;
}

.ui_page_transition_component--floor {
    bottom: 0;
    transform-origin: bottom;
}

.saved {display: none;}

.full_page_video,
.mobile_full_page_image,
.overlay {
    position: fixed;
    z-index: -1;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
}

.overlay {background: hsla(0, 0%, 0%, 20%);}

.load {display: block;}

.page_content,
.mobile_content {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
}

.page_content__heading_container {
    display: flex;
    justify-content: center;
    margin: 0 0 50px 0;
}

.page_content__in {
    display: inline-block;
    overflow: hidden;
}

.page_content__heading {
    display: inline-block;
    margin: 0;
    font: 800 12vw var(--w05);
    font-stretch: 125%;
    text-align: center;
    white-space: nowrap;
    transform-origin: left;
}

.page_content__desk_container,
.page_content__mobi_container,
.mobile_content__container {
    position: relative;
    left: 50%;
    width: 450px;
    transform: translateX(-50%);
}

.page_content__desk_container {display: none;}
.page_content__mobi_container, .page_content__ui_interaction_components {display: flex;}

.page_content__mobi_container {justify-content: center;}
.page_content__ui_interaction_components {justify-content: space-between;}

.page_content__info, .mobile_content__info {flex: 1;}

.page_content__play {
    width: 75px;
    height: 75px;
    padding: 0;
    border: none;
    border-radius: 50%;
    font: 100 1.6rem var(--w04);
    font-stretch: 112.5%;
    background: var(--primary-ui-color);
    cursor: pointer;
    pointer-events: none;
}

.page_content__play:hover {transform: scale(1.1);}

.page_content__description,
.mobile_content__description {
    display: inline-block;
    width: 250px;
    margin: 0;
    font: 100 1.6rem var(--w04);
    font-stretch: 112.5%;
    text-align: justify;
    transform-origin: top;
}

.page_content__badge {
    display: inline-flex;
    align-items: center;
    margin-top: 20px;
}

.page_content__badge svg {
    width: 18px;
}

.page_content__badge span {
    margin-top: 1px;
    margin-left: 10px;
    user-select: none;
}

.page_content__social,
.mobile_content__social {
    display: flex;
    align-items: center;
    margin: 125px 0 0 0;
}

.page_content__sns,
.mobile_content__sns {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin: 0 15px 0 0;
    transition: transform .3s ease;
    pointer-events: none;
}

.page_content__sns:nth-child(3),
.mobile_content__sns:nth-child(3) {
    margin: 0 18px 0 0;
    transform: translateY(2px);
}

.page_content__sns:nth-child(4), .mobile_content__sns:nth-child(4) {transform: translateY(1px);}
.page_content__sns:hover, .mobile_content__sns:hover {transform: translateY(-4px);}

.page_content__share_label,
.mobile_content__share_label {
    display: inline-block;
    margin: 0 12px 0 0;
    font: 100 1.6rem var(--w04);
    transform-origin: bottom;
}

.page_content__icon,
.mobile_content__icon {
    width: 30px;
    height: 30px;
    fill: var(--primary-ui-color);
}

.page_content__mobi_ui_interaction_components {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 40%;
}

.page_content__show_info {
    width: 50px;
    height: 50px;
    padding: 0;
    border: none;
    font: 100 1.6rem var(--w04);
    font-stretch: 112.5%;
    color: inherit;
    background: none;
    cursor: pointer;
}

.page_content__show_info::after {
    content: '';
    display: block;
    width: 30.4167px;
    height: 1px;
    margin: 0 auto;
    background: var(--primary-ui-color);
    transform: scaleX(0);
    transition: transform .3s;
}

.page_content__show_info:hover::after {transform: scaleX(1);}

.mobile_content {display: none;}

.mobile_content__container {
    display: flex;
    flex-direction: column;
    width: 250px;
}

.mobile_content__hide_info {
    display: inline-block;
    align-self: center;
    height: 25px;
    cursor: pointer;
}

.mobile_content__in {
    position: relative;
    top: 40%;
    width: 25px;
    height: 2px;
}

.mobile_content__in:nth-child(1) {transform: translateY(50%) rotate(45deg);}
.mobile_content__in:nth-child(2) {transform: translateY(-50%) rotate(-45deg);}

.mobile_content__ui_fragment {
    position: absolute;
    width: 100%;
    height: 100%;
    background: var(--primary-ui-color);
    transform: scaleX(0);
}

.mobile_content__in:nth-child(1) .mobile_content__ui_fragment {transform-origin: left;}
.mobile_content__in:nth-child(2) .mobile_content__ui_fragment {transform-origin: right;}

.mobile_content__description {margin: 30px 0 0 0;}
.mobile_content__social {margin: 75px 0 0 0;}

.plyr_ultra_container {
    position: fixed;
    z-index: 3;
    display: none;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.plyr_container {width: inherit;}

.plyr {
    width: 1280px;
    margin: 0 auto;
}

@media (max-height: 720px) {.plyr {width: 150vh;}}

@media (max-width: 767px) {
    .os__in {width: 100%;}

    .os__page_title {
        width: inherit;
        height: 20vw;
        line-height: 20vw;
    }
}

@media (min-width: 320px) {
    .scroll_indicator,
    .scroll_indicator_index {
        bottom: 20vh;
        right: 50px;
    }
}

@media (min-width: 360px) {
    .loader__progress_bar_container {width: 125px;}

    .scroll_indicator,
    .scroll_indicator_index {
        width: 75px;
        height: 75px;
    }

    .scroll_indicator_index {line-height: 75px;}

    .gl {grid-template-columns: 240px;}

    .gl__plane {
        width: 240px;
        height: 135px;
    }

    .page_content__play {
        width: 100px;
        height: 100px;
        font-size: 1.8rem;
    }

    .page_content__mobi_ui_interaction_components {width: 50%;}

    .page_content__show_info {font-size: 1.8rem;}
}

@media (min-width: 576px) {
    .gl {grid-template-columns: 320px;}

    .gl__plane {
        width: 320px;
        height: 180px;
    }

    .page_content__heading {font-size: 12vw;}
}

@media (min-width: 768px) {
    .loader__progress_bar_container {width: 150px;}
    
    .to_home {
        top: 50px;
        left: 50px;
        transform: translateX(0);
    }

    .scroll_indicator {
        top: 62.5px;
        bottom: unset;
        right: 62.5px;
    }

    .scroll_indicator_index {display: none;}
    
    .menu,
    .close_player {
        top: 50%;
        left: unset;
        right: 50px;
        transform: translate(0, -50%);
    }
    
    .menu--close__in, .close_player__in {top: 46.5%;}

    .scroll_hint {font-size: 4.8rem;}
    
    .scroll_layers__page_content,
    .os__in {
        width: 426px;
        height: 240px;
    }
    
    .scroll_layers__page_title {
        justify-content: left;
        width: 426px;
        font-size: 7.2rem;
    }
    
    .scroll_layers__page_title a {
        justify-content: left;
        height: 100px;
        transform: translate(-100px, 50px);
    }
    
    .scroll_index {display: inline;}
    .scroll_index.__isMobile {display: none;}

    .os__page_title {
        font-size: 4.8rem;
        line-height: 240px;
    }

    .menu_page {width: 50%;}

    .menu_page__list, .to_self_back {font-size: 4.8rem;}

    .menu_page__about {margin: 10px 0 20px 0;}

    .gl {
        grid-template-columns: repeat(2, 240px);
        gap: 100px 50px;
    }

    .gl__in:nth-child(even) {margin: 67.5px 0 0 0;}

    .gl__plane {
        width: 240px;
        height: 135px;
    }

    .page_content__heading_container {margin: 0 0 75px 0;}

    .page_content__heading {font-size: 6.4rem;}
}

@media (min-width: 1024px) {
    .scroll_hint {font-size: 6.4rem;}

    .scroll_layers__page_content,
    .os__in {
        width: 640px;
        height: 360px;
    }

    .scroll_layers__page_title {
        width: 640px;
        font-size: 9.6rem;
    }

    .scroll_layers__page_title a {height: 200px;}

    .os__page_title {
        font-size: 7.2rem;
        line-height: 360px;
    }

    .menu_page {
        flex-direction: row-reverse;
        width: 60%;
    }

    .menu_page__footer {
        flex: 1;
        align-self: flex-end;
        margin: 0;
    }

    .gl_container {margin: 500px 0 150px 0;}

    .gl {
        grid-template-columns: repeat(2, 320px);
        gap: 150px 50px;
    }

    .gl__in:nth-child(even) {margin: 90px 0 0 0;}

    .gl__plane {
        width: 320px;
        height: 180px;
    }

    .gl__page_title {
        margin: 40px 0 0 20px;
        font-size: 3.6rem;
    }

    .to_self_back {margin: 0 0 200px 0;}

    .page_content__heading_container {display: flex !important;}

    .page_content__heading {font-size: 9.8rem;}

    .page_content__desk_container {display: block;}
    .page_content__mobi_container {display: none !important;}

    .mobile_content {display: none !important;}
}

@media (min-width: 1280px) {
    .scroll_indicator,
    .scroll_indicator_index {
        right: 50px;
        width: 100px;
        height: 100px;
    }

    .scroll_hint {font-size: 9.6rem;}

    .scroll_layers__page_content,
    .os__in {
        width: 800px;
        height: 450px;
    }

    .scroll_layers__page_title {
        width: 800px;
        font-size: 12.2rem;
    }

    .scroll_layers__page_title a {
        height: 300px;
        transform: translate(-150px, 50px);
    }

    .os__page_title {
        font-size: 9.6rem;
        line-height: 450px;
    }

    .menu_page__list, .to_self_back {font-size: 7.2rem;}

    .menu_page__about, .menu_page__copyright {font-size: 1.8rem;}

    .menu_page__about {width: 225px;}

    .gl {gap: 150px 100px;}

    .page_content__heading {font-size: 12.2rem;}
}

@media (min-width: 1920px) {
    .menu_page {width: 70%;}

    .menu_page__list, .to_self_back {font-size: 9.6rem;}

    .gl_container {margin: 500px 0 250px;}

    .gl {
        grid-template-columns: repeat(2, 480px);
        gap: 150px;
    }

    .gl__in:nth-child(even) {margin: 135px 0 0 0;}

    .gl__plane {
        width: 480px;
        height: 270px;
    }

    .to_self_back {margin: 0 0 250px 0;}
}

@media (orientation: landscape) and (max-width: 568px) {
    .scroll_indicator,
    .scroll_indicator_index {
        width: 50px;
        height: 50px;
    }

    .scroll_indicator_index {line-height: 50px;}
}

@media (orientation: landscape) and (max-width: 740px) {
    .to_home {top: 5px;}

    .menu {bottom: 5px;}

    .scroll_indicator, .scroll_indicator_index {bottom: 40vh;}

    .scroll_hint {font-size: 4.5vw;}

    .scroll_layers__page_content {
        width: 320px;
        height: 180px;
    }

    .scroll_layers__page_title {
        width: 320px;
        height: 100%;
        font-size: 6vw;
    }

    .scroll_layers__page_title a {height: 12vw;}

    .page_content__mobi_ui_interaction_components {width: 40%;}

    .page_content__play {
        width: 75px;
        height: 75px;
        font-size: 1.6rem;
    }

    .page_content__show_info {font-size: 1.6rem;}
}

@media (orientation: landscape) and (max-width: 896px) {
    .scroll_index {bottom: 35px;}

    .menu_page {
        flex-direction: row-reverse;
        width: 60%;
    }

    .menu_page__list, .to_self_back {font-size: 4vw;}

    .menu_page__footer {
        flex: 1;
        align-self: flex-end;
        margin: 0;
    }

    .page_content__heading_container {margin: 0 0 8vh 0;}

    .page_content__heading {font-size: 8vw;}
}